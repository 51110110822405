<template>
  <sign-page
    ref="wagePage"
    title-text="职工工资"
    :request="request"
    :column-list="columnList"
    :title-menus="[{key: 'input', label: '导入'}, {key: 'outPut', label: '导出'}, {key: 'outPutP', label: '导出模版'}, {key: 'clear', label: '清空'}]"
    :searchParms="[{key: 'month', label: '月份', default: lastMenth, type: 'select', selectDatas: months}]"
    :table-actions="[]"
    table-size="large"
    :export-file-name="exportFileName"
    :dealSearchParm="dealSearchParm"
    :initSearchData="{month: lastMenth}"
    @clickTitleMenu="clickTitleMenu"
    :need-data-file="true">
    <input-file-update
      :fileParm="fileParm"
      :open-dialog.sync="openDialogFile"
      @inputFileUpdateOver="inputFileUpdateOver"
      @handleClose="openDialogFile = false">
    </input-file-update>
  </sign-page>
</template>

<script>
import {
  wageRequest as request
} from '../../api'

import {
  loadData
} from '../../auth_lib'

import {
  getLastMonth
} from '@/syslib/tools'

import {
  fileHepler
} from '@/fmlib'

import InputFileUpdate from '../../components/base/InputFileUpdate'

export default {
  components: {
    InputFileUpdate
  },
  computed: {
    months () {
      let i = 12
      let data = []
      while (i > -1) {
        data[i] = getLastMonth(data[i + 1] ? data[i + 1] + '-01' : null)
        i -= 1
      }
      return data.map((v) => {
        return {key: v, label: v}
      })
    },
    columnList () {
      return [
        { field: 'month', title: '月份', fixed: 'left', sort: true, dataType: Date },
        { field: 'sortNum', title: '序号', fixed: 'left', sort: true  },
        {field: 'name', title: '姓名', fixed: 'left', sort: true},
        {field: 'code', title: '工号', fixed: 'left', sort: true},
        {field: 'zw', title: '职务（岗位）工资',width: 180, sort: true, dataType: Number},
        {field: 'jb', title: '级别（薪级、技术）工资',width: 210, sort: true, dataType: Number},
        {field: 'jh', title: '教护10%工资',width: 180, sort: true, dataType: Number},
        {field: 'bl', title: '比例%', sort: true, dataType: Number},
        {field: 'zh', title: '折后工资', sort: true, dataType: Number},
        {field: 'gl', title: '工龄（教龄、护龄）工资', width: 210, sort: true, dataType: Number},
        {field: 'sy', title: '事业基础性津贴', sort: true, dataType: Number},
        {field: 'yf', title: '应发工资合计', sort: true, dataType: Number},
        {field: 'zf', title: '住房公积金', sort: true, dataType: Number},
        {field: 'yl', title: '医疗保险', sort: true, dataType: Number},
        {field: 'sybx', title: '失业保险', sort: true, dataType: Number},
        {field: 'yn', title: '养老保险', sort: true, dataType: Number},
        {field: 'zynx', title: '职业年金', sort: true, dataType: Number},
        {field: 'qt', title: '其他扣款项目', sort: true, dataType: Number},
        {field: 'kk', title: '扣款合计', sort: true, dataType: Number},
        {field: 'sf', title: '实发工资', fixed: 'right', sort: true, dataType: Number}
      ]
    }
  },
  methods: {
    async loadData (parm) {
      return await loadData(request, this, parm)
    },
    exportFileName () {
      return this.month + '职工工资'
    },
    dealSearchParm (parm) {
      this.month = parm.month
      if (!this.month) {
        this.$notify({
          title: '系统提示',
          message: '月份不能为空',
          type: 'info'
        })
        throw new Error('月份不能为空')
      }
      return parm
    },
    async clickTitleMenu (key) {
      if (key === 'clear') {
        if (!this.month) {
          this.$notify({
            title: '系统提示',
            message: '月份不能为空',
            type: 'info'
          })
          return 
        }
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定清空职工工资数据吗?'})
        if (result) {
          await request.clear({month: this.month})
          this.$refs.wagePage.loadData({month: this.month})
        }
      } else if (key === 'outPut') {
        if (!this.month) {
          this.$notify({
            title: '系统提示',
            message: '月份不能为空',
            type: 'info'
          })
          return 
        }
        let datas = await request.get({
          month: this.month
        })
        fileHepler.outPutTableData(datas, this.columnList, this.month + '职工工资.xlsx', this.month)
      } else if (key === 'outPutP') {
        fileHepler.outPutTableData([], this.columnList.filter(v => v.field !== 'month'), '职工工资模版.xlsx', '模版')
      } else {
        if (!this.month) {
          this.$notify({
            title: '系统提示',
            message: '月份不能为空',
            type: 'info'
          })
          return 
        }
        this.fileParm.sparm.month = this.month
        this.openDialogFile = true
      }
    },
    inputFileUpdateOver () {
      this.$refs.wagePage.loadData({month: this.month})
    }
  },
  data () {
    return {
      openDialogFile: false,
      lastMenth: getLastMonth(),
      request: Object.assign({}, request, {
        get: this.loadData
      }),
      fileParm: {
        sparm: {
          dataType: 'SourceWage',
          month: null
        },
        title: '导入工资',
        label: '工资文件'
      }
    }
  }
}
</script>
